<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <div class="row">
          <p v-if="calendar.id" class="project-header">แก้ไขรายละเอียดกิจกรรม {{ calendar.header }}</p>
          <p v-else class="project-header">เพิ่มกิจกรรมในปฏิทินการศึกษา</p>
        </div>

        <!-- Warning -->
        <div class="text-red-500">*กรุณาตรวจสอบว่ามีรายวิชาที่สอดคล้องกับข้อมูลที่เลือกภายในระบบก่อน (อื่น ๆ -> ตั้งค่ารายวิชา) มิเช่นนั้นจะไม่สามารถสร้างหรือแก้ไขกิจกรรมในปฏิทินการศึกษาได้*</div>

        <!-- Education year -->
        <div class="form-div mt-8">
          <p class="label -ml-4">ปีการศึกษา</p>
          <select v-model="calendar.year" class="w-6/12">
            <option v-for="year in years" :key="year.id" :value="year">{{ year }}</option>
          </select>
        </div>

        <!-- Semester -->
        <div class="form-div mt-8">
          <p class="label -ml-4">ภาคเรียนที่</p>
          <select v-model="calendar.semester" class="w-6/12">
            <option>1</option>
            <option>2</option>
          </select>
        </div>
        
        <!-- Course type -->
        <div class="form-div mt-8 mb-4">
          <p class="label -ml-4">รายวิชา</p>
          <select v-model="calendar.type" @change="changeType(calendar.type)" class="col-span-2">
            <option value="PJ">โครงงาน</option>
            <option value="CP">สหกิจศึกษา</option>
            <option value="IS">ศึกษาอิสระ</option>
          </select>
        </div>

        <!-- Program -->
        <div class="form-div mt-8">
          <p class="label -ml-4">หลักสูตร</p>
          <select v-model="calendar.program" class="col-span-2">
            <option v-for="program in programs" :key="program.id" :value="program.id">{{ program.name }}</option>
          </select>
        </div>

        <!-- Course: not found Error -->
        <p v-if="val.course" class="text-error">ไม่พบรายวิชาที่สอดคล้องกับข้อมูลที่เลือกภายในระบบ</p>
        <hr>

        <!-- Header (Calendar) -->
        <div class="form-div mt-4">
          <p class="label -ml-4">หัวข้อประกาศ</p>
          <input v-model="calendar.header" type="text" class="col-span-3">
        </div>

        <!-- Header: null Error -->
        <p v-if="val.header" class="text-error">กรุณากรอกข้อมูลสำหรับ "หัวข้อประกาศ"</p>

        <!-- Description -->
        <div class="form-div mt-8">
          <p class="label -ml-4">รายละเอียด</p>
          <textarea v-model="calendar.description" rows="5" class="col-span-3 pt-2"></textarea>
        </div>

        <!-- Description: null Error -->
        <p v-if="val.description" class="text-error">กรุณากรอกข้อมูลสำหรับ "รายละเอียด"</p>

        <!-- Datetime range -->
        <div class="form-div mt-8">
          <p class="label -ml-4">ระยะเวลา</p>
          <!-- IF this calendar created by course -->
          <router-link v-if="calendar.id && calendar.form_entry || calendar.id && calendar.is_101" :to="'/course/' + calendar.course + '/edit'" class="mr-8">
            <button class="edit-button" style="min-width:15rem;">แก้ไขระยะเวลาการกรอกแบบฟอร์ม</button>
          </router-link>
          <!-- IF this calendar created by user -->
          <div v-else class="col-span-3 flex flex-row">
            <input v-model="calendar.active_date_from" type="datetime-local">
            <p class="mx-4">ถึง</p>
            <input v-model="calendar.active_date_to" type="datetime-local">
          </div>
        </div>

        <!-- Datetime range: null Error -->
        <p v-if="val.range_null" class="text-error">กรุณากรอกข้อมูลสำหรับ "ระยะเวลา"</p>
        <!-- Datetime range: incorrect format Error -->
        <p v-if="val.range" class="text-error">ระยะเวลาไม่ถูกต้องตามรูปแบบ (ตรวจสอบวันที่ก่อน-หลัง)</p>

        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">บันทึกข้อมูล</button>
        <div v-if="validate_error" class="text-red-500 mt-4">พบข้อผิดพลาดในข้อมูลที่กรอก กรุณาตรวจสอบข้อมูลอีกครั้ง (มีการแสดงข้อผิดพลาดภายใต้ข้อมูลที่กรอก)</div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"
import { calendarValidate } from "../mixins/validate"
import { changeProgramName, changeProgramByType, academicYear } from "../mixins/other"

export default {
  name: "CalendarEdit",
  components: {
    NavBar
  },
  data () {
    return {
      loading: true,
      isSubmitted: false,
      calendar: {
        id: null,
        header: "",
        description: "",
        type: "PJ",
        semester: 1,
        year: new Date().getFullYear() + 543,
        program: 1,
        flag: "C",
        active_date_from: null,
        active_date_to: null,
      },
      programs: [],
      years: [],
      exist: false,
      val: {
        pass: false,
        course: false,
        header: false,
        description: false,
        range_null: false,
        range: false
      },
      role: null
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    this.courses = await this.$store.dispatch("getCourse")
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.calendar.type))
    this.years = academicYear(this.years)
    const calId = this.$route.params.id

    // Edit calendar
    if (calId) {
      this.calendar = await this.$store.dispatch("getAnnouncementById", calId)
      const course = await this.$store.dispatch("getCourseById", this.calendar.course)
      this.calendar['year'] = course.year
      this.calendar['semester'] = course.semester
      this.calendar['program'] = course.program
      this.calendar['type'] = course.type
      this.programs = changeProgramName(changeProgramByType(this.calendar.type))
      this.calendar.active_date_from = moment(new Date(this.calendar.active_date_from)).format('YYYY-MM-DDTHH:mm')
      this.calendar.active_date_to = moment(new Date(this.calendar.active_date_to)).format('YYYY-MM-DDTHH:mm')
      document.title = "แก้ไขรายละเอียดกิจกรรม " + this.calendar.header + " | Carbon"
    } 
    
    // Create calendar
    else {
      document.title = "เพิ่มกิจกรรมในปฏิทินการศึกษา | Carbon"
    }

    this.loading = false
  },
  methods: {
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        const data = this.calendar
        calendarValidate(data, this.courses, this.val)

        // Create calendar
        if (this.calendar.id === null && this.val.pass) {
          const cal = await this.$store.dispatch("createAnnouncement", data) 
          this.$router.push(`/calendar/${cal.id}`)
        } 
        
        // Edit calendar
        else if (this.calendar.id !== null && this.val.pass) {
          await this.$store.dispatch("updateAnnouncement", data)
          this.$router.push(`/calendar/${this.calendar.id}`)
        } 
        
        // Error
        else {
          this.validate_error = true
        }
        
        this.isSubmitted = false
        this.loading = false
      }
    },
    changeType(type) {
      this.programs = changeProgramName(changeProgramByType(type))
      if (type == 'IS') {
        this.calendar.program = 4
      } else {
        this.calendar.program = 1
      }
    }
  }
}
</script>