function resetValidate(data) {
    for (let key in data) {
        if (typeof(data[key]) == "string") {
            data[key] = ""
        } else if (typeof(data[key]) == "boolean") {
            data[key] = false
        }
    }
    return data
}

function announcementValidate(data, courses, validate) {
    validate = resetValidate(validate)
    validate.course = true
    for (let course in courses) {
        if (data.year == courses[course].year && data.semester == courses[course].semester && data.program == courses[course].program && data.type == courses[course].type) {
            validate.course = false
            break
        }
    }
    if (data.header == "" || data.header == "<p><br></p>") {
        validate.header = true
    }
    if (data.description == "" || data.description == "<p><br></p>") {
        validate.description = true
    }
    if (!validate.course && !validate.header && !validate.description) {
        validate.pass = true
    }
    return { validate }
}

function calendarValidate(data, courses, validate) {
    validate = resetValidate(validate)
    validate.course = true
    for (let course in courses) {
        if (data.year == courses[course].year && data.semester == courses[course].semester && data.program == courses[course].program && data.type == courses[course].type) {
            validate.course = false
            break
        }
    }
    if (data.header == "") {
        validate.header = true
    }
    if (data.description == "") {
        validate.description = true
    }
    if (data.active_date_from === null || data.active_date_to === null || data.active_date_from === "" || data.active_date_to === "") {
        validate.range_null = true
    } else if (data.active_date_from > data.active_date_to || data.active_date_to < data.active_date_from || data.active_date_from === data.active_date_to) {
        validate.range = true
    }
    if (!validate.course && !validate.header && !validate.description && !validate.range_null && !validate.range) {
        validate.pass = true
    }
    return { validate }
}

function courseValidate(data, courses, templates, forms, validate, isPostCourse, exist) {
    validate = resetValidate(validate)
    validate.other_form = []
    let all_active_from = []
    let all_active_to = []
    let form_entry_list = []

    /* Check: Course exist */
    for (let course in courses) {
        if (data.course.year == courses[course].year && data.course.semester == courses[course].semester && data.course.program == courses[course].program && data.course.type == courses[course].type) {
            if (isPostCourse && data.course.prev_course != null)
                validate.course = true
            else if (!isPostCourse)
                validate.course = true
            break
        }
    }

    if (isPostCourse) {
        if (data.course.prev_course == null) {
            validate.prev_course = "กรุณาเลือกข้อมูลสำหรับ \"รายวิชาก่อนหน้า\""
        }
    }

    if (!isPostCourse) {
        /* Check: 101 Datetime range */
        if (data.course.form_active_date_from === null || data.course.form_active_date_to === null || data.course.form_active_date_from === "" || data.course.form_active_date_to === "") {
            validate.first_error = "กรุณากรอกข้อมูลสำหรับ \"ระยะเวลา\" ของ แบบฟอร์มนำเสนอหัวข้อโครงงาน"
        } else if (data.course.form_active_date_from > data.course.form_active_date_to || data.course.form_active_date_to < data.course.form_active_date_from || data.course.form_active_date_from === data.course.form_active_date_to) {
            validate.first_error = "ระยะเวลาของ แบบฟอร์มนำเสนอหัวข้อโครงงาน ไม่ถูกต้องตามรูปแบบ (ตรวจสอบวันที่ก่อน-หลัง)"
        }
        all_active_from.push(data.course.form_active_date_from)
        all_active_to.push(data.course.form_active_date_to)
    }

    /* Check: Other form */
    for (let form in forms) {
        if (forms[form].form_entry === null) {
            validate.second_error = true
        }
        if (forms[form].form_entry !== null) {
            form_entry_list.push(forms[form].form_entry)
        }
    }

    for (let form in data.forms) {
        let title = ""
        all_active_from.push(data.forms[form].active_date_from)
        all_active_to.push(data.forms[form].active_date_from)
        for (let template in templates) {
            if (data.forms[form].form_entry == templates[template].id) {
                title = templates[template].title
            }
        }
        if (data.forms[form].active_date_from === null || data.forms[form].active_date_to === null || data.forms[form].active_date_from === "" || data.forms[form].active_date_to === "") {
            validate.other_form[form] = "กรุณากรอกข้อมูลสำหรับ \"ระยะเวลา\" ของ " + title
        } else if (data.forms[form].active_date_from > data.forms[form].active_date_to || data.forms[form].active_date_to < data.forms[form].active_date_from || data.forms[form].active_date_from === data.forms[form].active_date_to) {
            validate.other_form[form] = "ระยะเวลาของ " + title + " ไม่ถูกต้องตามรูปแบบ (ตรวจสอบวันที่ก่อน-หลัง)"
        }
    }

    validate.other_form = validate.other_form.filter(function(error) {
        return error !== undefined
    })

    validate.third_error = form_entry_list.some((val, i) => form_entry_list.indexOf(val) !== i)

    /* Check: Course datetime range */
    if (data.course.active_date_from === null || data.course.active_date_to === null || data.course.active_date_from === "" || data.course.active_date_to === "") {
        validate.range = "กรุณากรอกข้อมูลสำหรับ \"ระยะเวลา\""
    } else if (data.course.active_date_from > data.course.active_date_to || data.course.active_date_to < data.course.active_date_from || data.course.active_date_from === data.course.active_date_to) {
        validate.range = "ระยะเวลาไม่ถูกต้องตามรูปแบบ (ตรวจสอบวันที่ก่อน-หลัง)"
    }

    if (exist)
        validate.course = false

    if (!validate.course && validate.other_form.length == 0 && validate.first_error == "" && !validate.second_error && !validate.third_error && validate.range == "" && validate.prev_course == "") {
        validate.pass = true
    }

    return { validate }
}

function projectValidate(data, validate, type) {
    validate = resetValidate(validate)

    if (type === 'IS') {
        validate.is_category = true
        for (let category in data.is_category) {
            if (data.is_category[category] == true && category != 'agency') {
                validate.is_category = false
                break
            }
        }
    }

    if (data.course == null) {
        validate.course = true
    }

    if (data.advisors[0] == null) {
        validate.advisor_null = true
    } else if (data.advisors[0] == data.advisors[1]) {
        validate.advisor_same = true
    }

    if (data.category == null) {
        validate.category = true
    }

    if (data.keyword.split(',').length < 3 | data.keyword.split(',').includes('')) {
        validate.keyword = true
    }

    if (!validate.course & !validate.advisor_null & !validate.advisor_same & !validate.category & !validate.keyword & !validate.is_category) {
        validate.pass = true
    }

    return { validate }
}

export {
    announcementValidate,
    calendarValidate,
    courseValidate,
    projectValidate
}